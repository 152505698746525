// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-page-boutique-js": () => import("./../../src/templates/page/PageBoutique.js" /* webpackChunkName: "component---src-templates-page-page-boutique-js" */),
  "component---src-templates-page-page-category-js": () => import("./../../src/templates/page/PageCategory.js" /* webpackChunkName: "component---src-templates-page-page-category-js" */),
  "component---src-templates-page-page-contact-js": () => import("./../../src/templates/page/PageContact.js" /* webpackChunkName: "component---src-templates-page-page-contact-js" */),
  "component---src-templates-page-page-js": () => import("./../../src/templates/page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-page-page-redirect-js": () => import("./../../src/templates/page/PageRedirect.js" /* webpackChunkName: "component---src-templates-page-page-redirect-js" */),
  "component---src-templates-page-page-restaurant-js": () => import("./../../src/templates/page/PageRestaurant.js" /* webpackChunkName: "component---src-templates-page-page-restaurant-js" */),
  "component---src-templates-page-page-traiteur-js": () => import("./../../src/templates/page/PageTraiteur.js" /* webpackChunkName: "component---src-templates-page-page-traiteur-js" */)
}

